(function() {
	"use strict";

    angular
        .module('diary')
        .service('filterService', filterService);

    filterService.$inject = ['$filter', 'constants'];

    function filterService($filter, constants) {
		
		var self = this;
		
		self.defaults = {
			order_by	: 'time+',
			skip 		: 0,
			take 		: 100,
			date_start 	: null,
			date_end	: null,
			date 		: null,
			customer 	: null,
			postcode 	: null,
			phone		: null,
			assigned_to : null
		};
		
		self.filter = _.cloneDeep(self.defaults);

		self.past = _.cloneDeep(self.defaults);

		self.reset = function() {
			self.filter = _.cloneDeep(self.defaults);
		};

		self.hasFilterChanged = function() {

			console.log("current filter", self.filter);
			console.log("past filter", self.past);
			
			return !_.isEqual(self.filter, self.past);
		};

		self.setRecentSearchFilter = function() {
			self.past = _.cloneDeep(self.filter);
		};

		self.hasRecentSearchFilter = function() {
			return !_.isEqual(self.past, self.defaults);
		};

		self.getRecentSearchFilter = function() {
			self.filter = _.cloneDeep(self.past);
			return self.filter;
		};

		self.getNewFilter = function() {
			self.filter = _.cloneDeep(self.defaults);
			return self.filter;
		};

		self.getFilter = function() {
			return self.filter;
		};

		self.getSearchURL = function() {
			return self.cleanParameters(constants.date);
		};

		self.getSearchParams = function() {
			return self.cleanParameters(constants.timestamp);
		};

		self.hasMinimumParams = function(params) {
			return !_.isEmpty(_.omit(params, ['take', 'skip', 'order_by']));
		};

		self.cleanParameters = function(date_format) {
			
			var params = {};

			_.each(self.filter, function(f,k) {
				if (_.isNull(f) || _.isUndefined(f) || (_.isEmpty(f) && _.isString(f))) {

				} else {
					params[k] = f;
				}

				if (f instanceof Date) {
					params[k] = $filter('date')(f, date_format);
				}

				if (typeof f === 'number') {
					params[k] = f.toString();
				}
			});

			return params;
		};
	}

})();