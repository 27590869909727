/*
------------------------------------
Routing
------------------------------------
*/
(function() {
	"use strict";

	angular
		.module('diary')
		.config(Routes);

	Routes.$inject = ['$routeProvider', '$locationProvider', 'constants'];

	function Routes($routeProvider, $locationProvider, constants) {

		var getToday = function() {
			var now = new Date();
			var day = now.getDate();
			if (day < 10) {
				day = '0' + day;
			}
			var month = now.getMonth() + 1;
			if (month < 9) {
				month = '0' + month;
			}
			var year = now.getFullYear();
			
			return year + '-' + month + '-' + day;
		};

		$locationProvider.html5Mode(false).hashPrefix('!');
		
		$routeProvider

			.when('/login', {
				templateUrl: '/partials/login.tpl.html',
				controller: 'LoginController',
				controllerAs : 'lvm',
				title: 'Login'
			})

			.when('/search', {
				templateUrl: '/partials/search.tpl.html',
				controller: 'SearchController',
				controllerAs: 'svm',
				title: 'Search',
				resolve: {
					mode : function() {
						return {
							search: true,
							diary: false
						};
					},
					'permissions' : ['Auth', '$location', function(Auth, $location) {

						if (Auth.isRestricted()) {
							$location.path('/diary');
						}
					}]
				}
			})

			.when('/diary', {
				redirectTo: '/diary/' + getToday()
			})

			.when('/diary/:date', {
				templateUrl: '/partials/search.tpl.html',
				controller: 'SearchController',
				controllerAs: 'svm',
				title: 'Diary',
				resolve: {
					mode : function() {
						return {
							search: false,
							diary: true
						};
					}
				}
			})

			.when('/notes', {
				redirectTo: '/notes/' + getToday()
			})


			.when('/notes/:date', {
				templateUrl: '/partials/note.tpl.html',
				controller: 'NoteController',
				controllerAs: 'nvm',
				title: 'Notes',
			})

			.when('/job/new', {
				redirectTo: '/job/new/' + getToday()
			})
			
			.when('/job/new/:date', {
				templateUrl: '/partials/job.tpl.html',
				controller: 'JobController',
				controllerAs: 'jvm',
				title: 'Add Job',
				resolve: {
					mode : function() {
						return {
							duplicate : false,
							create: true,
							edit:false
						};
					},
					'permissions' : ['Auth', '$location', function(Auth, $location) {

						if (Auth.isRestricted()) {
							$location.path('/diary');
						}
					}]
				}
			})

			.when('/job/:id', {
				templateUrl: '/partials/job.tpl.html',
				controller: 'JobController',
				controllerAs: 'jvm',
				title: 'View/Edit Job',
				resolve: {
					mode : function() {
						return {
							duplicate : false,
							create: false,
							edit: true
						};
					}
				}
			})

			.when('/job/:id/duplicate', {
				templateUrl: '/partials/job.tpl.html',
				controller: 'JobController',
				controllerAs: 'jvm',
				title: 'Duplicate Job',
				resolve: {
					mode : function() {
						return {
							duplicate : true,
							create: true,
							edit: false
						};
					},
					'permissions' : ['Auth', '$location', function(Auth, $location) {

						if (Auth.isRestricted()) {
							$location.path('/diary');
						}
					}]
				}
			})

			.when('/admin', {
				templateUrl: '/partials/admin.tpl.html',
				controller: 'AdminController',
				controllerAs: 'avm',
				title: 'Admin Dashboard',
				resolve : {
					'permissions' : ['Auth', '$location', function(Auth, $location) {

						if (!Auth.isAdmin()) {
							$location.path('/diary');
						}
					}]
				}
			})

			.otherwise({
				redirectTo: '/login'
			});
	}

})();