(function() {
	"use strict";

    angular
        .module('diary')
        .factory('urlService', urlService);

    urlService.$inject = ['$location'];
    
    function urlService($location) {

		var history = [];

		var getURL = function() {
			return $location.absUrl();
		};

		var getDefault = function() {
			return '/diary';
		};

		var getPreviousURL = function() {
			var url = getDefault();

			if (history.length > 1) {
				url = history[history.length - 2];
			}

			console.log('Previous URL', url);
			return url;
		};

		var addURL = function(url) {
			history.push(url);
			console.log('Adding URL to History', history);

			if (history.length > 50) {
				history = history.slice(0, history.length - 10);
			}
		};

		return {
			'getURL' : getURL,
			'getDefault' : getDefault,
			'getPreviousURL' : getPreviousURL,
			'addURL' : addURL
		};
	}

})();