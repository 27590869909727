(function() {
    "use strict";

    angular
        .module('diary')
        .directive('ngEscape', ngEscape);

    ngEscape.$inject = [];

    function ngEscape() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {

                var escape = function(e) {

                    if (e.which === 27) {
                        scope.$apply(function() {
                            scope.$eval(attrs.ngEscape);
                        });

                        e.preventDefault();
                    }
                };

                element.on('keydown keypress', escape);

                scope.$on('$destroy', function() {
                    element.off('keydown keypress', escape);
                });
            }
        };
    }

})();