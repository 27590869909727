(function() {
	"use strict";
	
	angular
		.module('diary')
		.controller('SendToDeviceModalController', SendToDeviceModalController);

	SendToDeviceModalController.$inject = ['Push', 'data', 'notificationService', 'Feedback', '$mdDialog'];

	function SendToDeviceModalController(Push, data, notificationService, Feedback, $mdDialog) {

		var mvm = this;

		mvm.validation = Feedback;

		mvm.notification = {};

		if (data.job) {

			mvm.notification.title = data.job.address.postcode;


			mvm.notification.body = data.job.customer.full_name;

			if (data.job.customer.company) {
				mvm.notification.body += ' at ' + data.job.customer.company;
			}

			mvm.notification.body += '\n' + data.job.appointment.label;

			mvm.notification.body += '\n' + data.job.address.house_number + ', ' + data.job.address.street + ', ' + data.job.address.city + ', ' + data.job.address.postcode;

			if (data.job.contact.name) {
				mvm.notification.body += '\n' + 'Contact name: ' + data.job.contact.name;
			}

			if (data.job.contact.number) {
				mvm.notification.body += '\n' + 'Contact number: ' + data.job.contact.number;
			}

			if (data.job.customer.mobile) {
				mvm.notification.body += '\n' + 'Customer mobile: ' + data.job.customer.mobile;
			}

			if (data.job.customer.phone) {
				mvm.notification.body += '\n' + 'Customer phone: ' + data.job.customer.phone;
			}

			if (data.job.description) {
				mvm.notification.body += '\n\n' + 'Notes: ' + data.job.description;
			}

			mvm.notification.job = data.job.id;
		}

		Push.getContacts().then(function(response) {

			mvm.contacts = _.filter(response.data.data.contacts, function(contact) {
				return contact.with && (contact.with.name && contact.with.email);
			});

		}, notificationService.requestFailed);

		mvm.isContactAssigned = function(contact) {
			if (!data.job.assigned_to || !contact.with || !contact.with.name) {
				return false;
			}

			return (contact.with.name.toLowerCase().indexOf(data.job.assigned_to.first_name.toLowerCase()) > -1);
		};

		mvm.selectContact = function(email) {
			if (email) {
				mvm.notification.email = email;
			}
		};

		mvm.cancel = function() {
			$mdDialog.cancel();
		};

		mvm.send = function() {

			Push.create(mvm.notification).then(function(response) {
				
				$mdDialog.hide();

				notificationService.createNotification({
					'http' : response,
					'code' : 's:sendtodevice'
				});

			}, function(response) {



			});
		};

	}
	
})();