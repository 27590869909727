(function() {
    "use strict";

    angular
        .module('diary')
        .controller('EditorController', EditorController)
        .directive('editor', EditorDirective);

    
    EditorController.$inject = ['Feedback'];
    EditorDirective.$inject = ['$timeout'];

    function EditorController(Feedback) {

        var evm = this;

        // --------------------------------------------
        // Setup
        // --------------------------------------------

        var original = null;

        evm.messages = Feedback;
        
        evm.edit = false;


        // --------------------------------------------
        // UI Controls
        // --------------------------------------------

        evm.toggleEditor = function() {
            original = _.clone(evm.model);
            evm.edit = !evm.edit;
        };

        evm.cancel = function() {
            evm.edit = false;
            evm.model = original;
        };

        evm.save = function() {
            evm.edit = false;
            evm.onUpdate();
        };
    }


    function EditorDirective($timeout) {
        return {
            restrict: "E",
            replace: true,
            bindToController : {
                model: '=',
                label: '@',
                onUpdate : '&',
                validation: '='
            },
            controller : 'EditorController',
            controllerAs : 'evm',
            templateUrl : '/partials/editor.tpl.html',
            link: function(scope, element, attr, ctrl) {


                var editor = angular.element(element[0].querySelector('.editor-original'));

                editor.on('click', function(e) {
                    scope.$apply(function() {
                        ctrl.toggleEditor();                        
                        
                        $timeout(function() {
                            var input = element[0].querySelector('.editor-input');
                            if (input) {
                                input.focus();
                            }                            
                        }, 0, false);
                    });
                });

                element.on('$destroy', function() {
                    editor.off('click');
                });
            }
        };
    }

})();