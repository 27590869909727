(function() {
	"use strict";

    angular
        .module('diary')
        .factory('Note', NoteService);

    NoteService.$inject = ['API', 'constants', '$filter', '$routeParams'];

    function NoteService(API, constants, $filter, $routeParams) {

		var getNoteModel = function() {

			var urlDate = Date.parse($routeParams.date + 'T00:00');

			return _.cloneDeep({
				'date': ((_.isFinite(urlDate)) ? new Date(urlDate) : new Date()),
				'endDate' : ((_.isFinite(urlDate)) ? new Date(urlDate) : new Date()),
				'note': null
			});
		};

		var getNotes = function(when) {

			var config = {
				headers : {},
				url: '/notes'
			};

			if (when) {
				config.headers['note-date'] = when;
			} else {
				config.headers['note-date'] = $filter('date')(new Date(), constants.timestamp);
			}

			return API.get(config);
		};

		var create = function(body) {

			body.date = $filter('date')(body.date, constants.timestamp);
			body.endDate = $filter('date')(body.endDate, constants.timestamp);
		
			return API.post({
				data: {'note' : body},
				url: '/note'
			});
		};

		var update = function(body) {
			return API.put({
				data: {'note' : body},
				url: '/note/' + body.id
			});
		};

		var remove = function(body) {
			return API.patch({
				data: {'note' : body},
				url: '/note/' + body.id
			});
		};

		return {
			'getNoteModel' : getNoteModel,
			'getNotes' : getNotes,
			'create' : create,
			'update' : update,
			'remove' : remove
		};
	}

})();