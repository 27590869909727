(function() {
	"use strict";

	angular
		.module('diary')
		.filter('permission', PermissionFilter);

	PermissionFilter.$inject = ['constants', '$filter'];

	function PermissionFilter(constants, $filter) {
		return function(input, parameter) {
			if (input === constants.permissions.admin) {
				return 'Administrator';
			}

			if (input === constants.permissions.standard) {
				return 'Standard';
			}

			if (input === constants.permissions.restricted) {
				return 'Restricted / (Engineer)';
			}

			return 'Unknown';
		};
	}

})();