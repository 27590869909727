(function() {
	"use strict";

	angular
		.module('diary')
		.controller('LoginController', LoginController);

	LoginController.$inject = ['Modal', '$location', 'constants', 'Feedback', 'Auth', 'Token', 'notificationService', 'urlService'];

	function LoginController(Modal, $location, constants, Feedback, Auth, Token, notificationService, urlService) {

		var lvm = this;

		lvm.companyName = constants.company.name;
		lvm.validation = Feedback;


		// --------------------------------------------
		// Auth PreCheck
		// --------------------------------------------

		if (Auth.isAuthenticated()) {
			$location.path(urlService.getDefault());
		}




		// --------------------------------------------
		// Show notifications
		// --------------------------------------------

		var notifications = notificationService.getDeferredNotifications();

		console.log("Deferred notifications", notifications);

		if (notifications.length) {
			_.each(notifications, function(n, k) {
				notificationService.fireNotification(n);
			});
		}




		// --------------------------------------------
		// Login
		// --------------------------------------------
		
		lvm.login = function() {

			var credentials = {
				'username': lvm.username,
				'password': lvm.password
			};

			Auth.login(credentials).then(function(response) {

				var header = response.headers('token');

				if (!header) {
					notificationService.createNotification({
						http: response,
						code: "g:unauthorized"
					});
				}

				var token = Token.parse(header);

				if (token && _.isEqual(token.user, response.data.data.user)) {

					Token.set(header);
					
					$location.path(urlService.getDefault());

					Token.start();

				} else {

					notificationService.createNotification({
						http: response,
						code: "g:unauthorized"
					});
				}

			}, notificationService.loginFailed);
		};



		// --------------------------------------------
		// Forgot Password
		// --------------------------------------------

		lvm.forgotPassword = function(e) {

			Modal.alert('forgotPasswordAlert').then(function() {

			}, function() {
				
			});

			/*
			Modal.show({
				event: e,
				type: 'forgotPassword',
				submit: function(response) {
					console.log('modal success callback', response);
					//create inline notification
				},
				cancel : function(response) {
					//failed
				}
			});
			*/
		};
	}

})();