(function() {
	"use strict";

    angular
        .module('diary')
        .controller('JobController', JobController);

    JobController.$inject = ['$q', 'dateService', 'Auth', '$location', '$filter', '$routeParams', 'mode', 'constants', 'Feedback', 'Config', 'Customer', 'jobService', 'History', 'Search', 'notificationService', 'urlService', 'Modal'];

    function JobController($q, dateService, Auth, $location, $filter, $routeParams, mode, constants, Feedback, Config, Customer, jobService, History, Search, notificationService, urlService, Modal) {
		
		// --------------------------------------------
		// Initialisation & Setup
		// --------------------------------------------

		var jvm = this;

		jvm.app = {
			constants : constants,
			validation: Feedback,
			mode: mode
		};

		jvm.job = jobService.getJobModel();

		if ($routeParams.date) {
			var m = moment($routeParams.date, 'YYYY-MM-DD');
			jvm.job.date = (m.isValid()) ? m.toDate() : new Date();
		}

		var configs = [
			Config.getUsers(),
			Config.getStatuses(),
			Config.getReferrers(),
			Config.getAppointments()
		];

		$q.all(configs).then(function(response) {

			jvm.config = {};

			_.forEach(response, function(r, k) {
				var key = Object.keys(r.data.data)[0];
				jvm.config[key] = r.data.data[key];

				console.log(key, jvm.config[key]);
			});

			var tasks = [];

			if (_.isFinite(parseInt($routeParams.id))) {

				tasks.push(jobService.getJobById($routeParams.id));

				$q.all(tasks).then(function(response) {

					var data = [];

					_.forEach(response, function(r, k) {
						var key = Object.keys(r.data.data)[0];
						data[key] = r.data.data[key];
						console.log(key, data[key]);
					});

					if (jvm.app.mode.duplicate) {
						data.job.status = jvm.config.statuses[0];
						data.job.referrer = jvm.config.referrers[0];
						data.job.time_of_booking = dateService.getToday();
					}

					jvm.job = jobService.parseJob(data.job);
					jvm.job_history = data.job_history;
					jvm.updateClone(jvm.job);


				}, notificationService.requestFailed);
			}

		}, notificationService.requestFailed);


		// --------------------------------------------
		// Customer editing / fog
		// --------------------------------------------

		var inProgress = false;

		jvm.isEditInProgress = function() {
			return inProgress;
		};

		jvm.isRestricted = function() {
			return Auth.isRestricted();
		};

		jvm.editInProgress = function(flag) {
			inProgress = flag;
		};

		jvm.onCustomerAddressSelect = function(address) {
			if (address) {
				jvm.job.address = _.cloneDeep(address);
				jvm.enterManualAddress();
			}
		};

		jvm.onCustomerSave = function() {
			jvm.updateClone(jvm.job);
		};

		jvm.updateClone = function(job) {
			if (job) {
				jvm.clone = _.cloneDeep(job);				
			}
		};



		// --------------------------------------------
		// Address editing / fog
		// --------------------------------------------

		jvm.selectAddress = function(address) {
			if (address) {
				jvm.job.address = address;
				manualAddress = true;
				searchAddress = false;
			}
		};

		jvm.hasAddress = function() {
			if (jvm.job.address && (jvm.job.address.house_number || jvm.job.address.street || jvm.job.address.city || jvm.job.address.postcode)) {
				return true;
			} else {
				return false;
			}
		};

		var manualAddress = false;

		jvm.enterManualAddress = function() {
			manualAddress = true;
		};

		jvm.isManualAddress = function() {
			return manualAddress;
		};


		// --------------------------------------------
		// Address search
		// --------------------------------------------

		var searchAddress = false;

		jvm.isSearchManualAddress = function() {
			return searchAddress;
		};

		jvm.searchManualAddress = function() {
			searchAddress = true;
			manualAddress = false;
		};

		jvm.cancelSearchAddress = function() {
			searchAddress = false;
			manualAddress = true;
		};






		// --------------------------------------------
		// History
		// --------------------------------------------

		jvm.getJobHistory = function() {
			if ($routeParams.id && !jvm.job_history) {
				History.getJobHistory($routeParams.id).then(function(response) {
					jvm.job_history = response.data.data.job_history;
				});
			}
		};


		// --------------------------------------------
		// Related
		// --------------------------------------------

		jvm.getRelatedJobs = function() {
			if (jvm.job && !jvm.job_related) {
				Search.jobs({'postcode' : jvm.job.address.postcode}).then(function(response) {
					jvm.job_related = _.filter(response.data.data.results, function(job) {
						return job.id !== jvm.job.id;
					});
				});				
			}
		};



		

		// --------------------------------------------
		// UI Controls
		// --------------------------------------------

		jvm.clearInput = function(property) {
			jvm.job[property] = null;
		};

		jvm.isUsingCustomerAddress = function() {
			if (jvm.job.customer && jvm.job.customer.address &&
				jvm.job.customer.address.house_number && jvm.job.customer.address.street && jvm.job.customer.address.city && jvm.job.customer.address.postcode) {
				//return _.isEqual(jvm.job.customer.address, jvm.job.address);
				return true;
			} else {
				return false;
			}
		};

		jvm.copyCustomerAddress = function() {
			if (jvm.isUsingCustomerAddress()) {				
				jvm.job.address = _.clone(jvm.job.customer.address, true);
				jvm.enterManualAddress();
			}
		};

		jvm.duplicate = function() {

			if (jvm.hasChanges()) {
				
				Modal.confirm('leaveJob').then(function() {
					duplicate();
				});

			} else {

				duplicate();
			}
		};

		var duplicate = function() {
			notificationService.createNotification({
				http: null,
				code: "j:duplicated"
			});

			var url = $location.path() + '/duplicate';
			$location.path(url);
		};

		jvm.hasChanges = function() {
			return !_.isEqual(jvm.job, jvm.clone);
		};

		jvm.undo = function() {
			jvm.job = _.cloneDeep(jvm.clone);			
			
			if (jvm.isEditInProgress) {
				jvm.editInProgress(false);
			}
		};

		jvm.cancel = function() {

			if (jvm.hasChanges()) {
				
				Modal.confirm('leaveJob').then(function() {					
					if (mode.duplicate) {
						$location.path('/');
					} else {
						$location.path(urlService.getPreviousURL());
					}
				});

			} else {

				if (mode.duplicate) {
					$location.path('/');
				} else {
					$location.path(urlService.getPreviousURL());
				}
			}
		};

		jvm.gotoJob = function(id) {
			$location.path('/job/' + id);
		};

		jvm.delete = function(e) {

			console.log('Deleting Job', jvm.job.id);

			Modal.confirm('deleteJob').then(function() {
				
				jobService.patchJob(jvm.job.id, {deleted : true}).then(function(response) {

					notificationService.deferNotification({
						http: response,
						code: 'j:deleted'
					});

					redirect(jvm.job.date);
				
				}, notificationService.requestFailed);

			}, function() {
				
			});
		};

		jvm.save = function() {

			var job = _.cloneDeep(jvm.job);

			if (!jobService.hasMinimumProperties(job)) {

				//TODO: add type
				notificationService.createNotification({
					http: null,
					code: "j:noparams"
				});
				
				return;
			}

			job = jobService.prepareJob(job);

			if (mode.create) {

				if (!job.customer.id) {
				
					var customer = job.customer;

					if (!Customer.hasMinimumProperties(customer)) {
						//TODO: add type
						notificationService.createNotification({
							http: null,
							code: "c:noparams"
						});

						return;
					}
					
					customer = Customer.prepare(customer);			
					Customer.create(customer).then(function(response) {

						var slashes = response.headers().resource.split('/');

						job.customer.id = slashes[slashes.length - 1];
						jvm.job.customer.id = slashes[slashes.length - 1];

						addJob(job);

					}, notificationService.requestFailed);

				} else {
					addJob(job);
				}				

			} else {
				editJob(job);				
			}
		};

		function addJob(job) {
			jobService.createJob(job).then(function(response) {

				notificationService.deferNotification({
					http: response,
					code: (mode.edit) ? "j:updated" : "j:created"
				});
				
				redirect(jvm.job.date);

			}, notificationService.requestFailed);
		}

		function editJob(job) {
			jobService.editJob(job).then(function(response) {

				notificationService.deferNotification({
					http: response,
					code: (mode.edit) ? "j:updated" : "j:created"
				});

				redirect(jvm.job.date);

			}, notificationService.requestFailed);
		}

		function redirect(date) {
			$location.path('/diary/'+ $filter('date')(date, constants.diaryDate));
		}
	}

})();