(function() {
	"use strict";

    angular
        .module('diary')
        .controller('NoteController', NoteController);

    NoteController.$inject = ['$filter', '$mdDialog', 'dateService', 'constants', 'Note', 'notificationService', '$routeParams', '$location'];

    function NoteController($filter, $mdDialog, dateService, constants, Note, notificationService, $routeParams, $location) {

		// --------------------------------------------
		// Initialisation & Setup
		// --------------------------------------------

		var nvm = this;

		var urlDate = Date.parse($routeParams.date + 'T00:00');

		nvm.app = {
			constants: constants,
			today : (_.isFinite(urlDate)) ? new Date(urlDate) : new Date()
		};

		nvm.dates = {
			'before' : dateService.previousDay(nvm.app.today),
			'now' : nvm.app.today,
			'next' : dateService.nextBusinessDay(nvm.app.today)
		};



		// --------------------------------------------
		// Get the Notes
		// --------------------------------------------

		var date = $filter('date')(nvm.dates.now, constants.timestamp);

		
		nvm.getNotes = function() {

			Note.getNotes(date).then(function(response) {

				nvm.notes = response.data.data.notes;
				console.log("notes", response.data.data.notes);

			}, notificationService.requestFailed);
		};

		nvm.getNotes();
	



		// --------------------------------------------
		// Datepicker
		// --------------------------------------------

		nvm.jumpToToday = function() {
			processDate(new Date());
		};

		nvm.jumpToNextDate = function() {
			var tomorrow = dateService.nextDay(nvm.dates.now);
			processDate(tomorrow);
		};

		nvm.jumpToBeforeDate = function() {
			var yesterday = dateService.previousDay(nvm.dates.now);
			processDate(yesterday);
		};

		var processDate = function(date) {

			if (date.getDate() === nvm.dates.now.getDate() && date.getMonth() === nvm.dates.now.getMonth() && date.getFullYear() === nvm.dates.now.getFullYear()) {
				return;
			}

			nvm.dates.now = date;

			nvm.changeDate();			
		};

		nvm.changeDate = function() {
			$location.path('/notes/' + $filter('date')(nvm.dates.now, constants.diaryDate));
		};

		nvm.filterWeekdays = function(date) {
			var day = date.getDay();
    		return day === 1 || day === 2 || day === 3 || day === 4 || day === 5;
		};



		// --------------------------------------------
		// Edit
		// --------------------------------------------

		nvm.updateNote = function(note) {

			console.log('updating note....');

			Note.update(note).then(function(response) {

				notificationService.createNotification({
					http: response,
					code: "n:updated"
				});

			}, notificationService.requestFailed);
		};




		// --------------------------------------------
		// Delete
		// --------------------------------------------

		nvm.confirmDelete = function(e, note) {
			
			var confirm = $mdDialog.confirm()
				.title('Delete')
				.ariaLabel('Delete')
				.textContent('Are you sure you want to delete this note?')
				.targetEvent(e)
				.ok('Yes')
				.cancel('Cancel');
			
			$mdDialog.show(confirm).then(function() {

				note.deleted = true;
				
				Note.remove(note).then(function(response) {

					notificationService.createNotification({
						http: response,
						code: "n:deleted"
					});

				}, notificationService.requestFailed);
			
			}, function() {
				
				//modal closed

				note.deleted = false;
			});
		};
	}

})();