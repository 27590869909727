(function() {
	"use strict";

    angular
        .module('diary')
        .factory('Search', SearchService);

    SearchService.$inject = ['API', 'constants'];

    function SearchService(API, constants) {

		var jobs = function(params) {
			return API.post({
				data: params,
				url: '/search/jobs'
			});
		};

		var countJobStatuses = function(results, deleted) {

			var res;

			if (!deleted) {
				res = _.filter(results, function(row) {
					return !row.deleted;
				});
			} else {
				res = results;
			}

			var count = _.groupBy(res, function(row) {
				return row.status.key;
			});

			_.each(count, function(c, k) {
				count[k] = c.length;
			});

			count.total = res.length;

			return count;
		};

		var customers = function(params) {
			return API.post({
				data : params,
				url : '/search/customers'
			});
		};

		return {
			'jobs' : jobs,
			'countJobStatuses' : countJobStatuses,
			'customers' : customers
		};
	}

})();