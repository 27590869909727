(function() {
	"use strict";

    angular
        .module('diary')
        .constant('Feedback', {
			maxlength: 'You have entered too many characters.',
			minlength: 'You have not entered enough characters.',
			required: 'Required. Cannot be blank.',
			postcode: 'Please enter a valid postcode.',
			mindate: 'You cannot select dates in the past.',
			date: 'Invalid date.',
			searchmindate: 'You cannot select a date before the start date.',
			searchmaxdate: 'You cannot select a date after the end date.',
			jobminimum: 'You have no provided the minimum fields to create a job.',
			email: 'Email address looks invalid.',
			username: 'Username looks invalid. It\'s usually an email.',
			password : 'Password must be at least 6 characters.',
			phone: 'Please enter a valid phone number.',
			mobile: 'Please enter a valid mobile number.',
			contact: 'Please enter a valid mobile or phone number.',
			phonerequired: 'A phone or mobile is required.',
			numbers : 'You can only enter numbers.',
			passwordmatch : 'Passwords must match'
		}
	);

})();