(function() {
	"use strict";

	angular
		.module('diary')
		.controller('FindAddressController', FindAddressController)
		.directive('findAddress', FindAddressDirective);

	FindAddressController.$inject = ['Address', 'Feedback'];
	FindAddressDirective.$inject = [];

	function FindAddressController(Address, Feedback) {

		var favm = this;

		favm.app = {
			validation : Feedback
		};

		favm.loading = false;
		favm.results = [];
		favm.searched = false;


		favm.find = function(keyword) {

			if (keyword) {
				favm.loading = true;

				var postcode = keyword.replace(/[\s]/g, '').toUpperCase();

				Address.get(postcode).then(function(response) {

					favm.results = response.data.data.addresses;
					favm.resultsReason = null;

				}, function(response) {
					
					favm.results = [];					

					if (response.data) {
						console.log('Address error: ', response.data.data.message);
						favm.resultsReason = response.data.data.message;
					}

				}).finally(function() {
					favm.searched = true;
					favm.loading = false;
				});
			}
		};

		favm.select = function(address) {

			if (!address || !_.isObject(address) || !favm.onSelect) {
				return;
			}

			var partial = {
				'house_number' : address.premise,
				'street' : address.thoroughfare,
				'city' : _.titleCase(address.post_town),
				'postcode' : address.postcode
			};

			favm.onSelect({'address' : partial});
			favm.searched = false;
		};

		favm.cancel = function() {
			favm.onCancel();
		};
	}

	function FindAddressDirective() {
		return {
			restrict : 'E',
			controller : 'FindAddressController',
			controllerAs : 'favm',
			bindToController : {
				'onSelect' : '&',
				'onCancel' : '&'
			},
			templateUrl : '/partials/address.tpl.html',
			link : function(scope, element, attr) {

			}
		};
	}
	
})();