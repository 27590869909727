(function() {
	"use strict";

    angular
        .module('diary')
        .factory('Config', ConfigService);

    ConfigService.$inject = ['API', 'notificationService'];

    function ConfigService(API, notificationService) {

		var getUsers = function() {
			return API.get({
				url: '/users',
				cache : false
			});
		};

		var getStatuses = function() {
			return API.get({
				url: '/statuses',
				cache : true
			});
		};

		var getReferrers = function() {
			return API.get({
				url: '/referrers',
				cache : true
			});
		};

		var getAppointments = function() {
			return API.get({
				url: '/appointments',
				cache : true
			});
		};



		return {
			'getUsers' : getUsers,
			'getStatuses' : getStatuses,
			'getReferrers' : getReferrers,
			'getAppointments' : getAppointments
		};
	}

})();