(function() {
	"use strict";

    angular
        .module('diary')
        .factory('Referrer', ReferrerService);

    ReferrerService.$inject = ['API'];

    function ReferrerService(API) {

    	//get

        //add

    	//remove

		return {
			
		};
	}

})();