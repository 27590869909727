(function() {
	"use strict";

	angular
		.module('diary')
		.factory('ModalTypes', ModalTypes);

	ModalTypes.$inject = [];

	function ModalTypes() {

		var types = {
			'forgotPassword' : {
				templateUrl : '/partials/forgotPassword.tpl.html',
                controller : 'ForgotPasswordModalController',
				clickOutsideToClose : true,
                escapeToClose : true,
                hasBackdrop : true
			},
			'changePassword' : {
				templateUrl : '/partials/changePassword.tpl.html',
				controller: 'ChangePasswordController',
				clickOutsideToClose : true,
				escapeToClose : true,
				hasBackdrop : true
			},
			'forgotPasswordAlert' : {
				title : 'Forgot Password',
				textContent : 'This feature is coming soon. For now, please contact Rich McKenzie.',
				ok : 'OK'
			},
			'deleteJob' : {
				title : 'Are you sure you want to delete this job?',
				textContent : 'You can undo this at anytime.',
				ok : 'Delete',
				cancel : 'Cancel'
			},
			'leaveJob' : {
				title : 'You have unsaved changes',
				textContent : 'Are you sure you want to leave this job without saving your changes?',
				ok : 'Yes, continue',
				cancel : 'No, go back'
			},
			'sendToDevice' : {
				templateUrl : '/partials/sendToDevice.tpl.html',
				controller : 'SendToDeviceModalController',
				clickOutsideToClose : true,
                escapeToClose : true,
                hasBackdrop : true
			},
			'manageUser' : {
				templateUrl : '/partials/manageUser.tpl.html',
				controller : 'ManageUserModalController',
				clickOutsideToClose : true,
                escapeToClose : true,
                hasBackdrop : true
			}
		};

		return {
			get : function(key) {
				console.log('Find Modal type:', key);
                return types[key];
			}
		};
	}
})();
