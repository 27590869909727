(function() {
	"use strict";

	angular
		.module('diary')
		.directive('notesTicker', NotesTickerDirective);

	NotesTickerDirective.$inject = ['Note', '$filter', '$routeParams', 'constants'];

	function NotesTickerDirective(Note, $filter, $routeParams, constants) {
		return {
			restrict : 'E',
			replace: true,
			controller : angular.noop,
			controllerAs : 'ntvm',
			template : '<div class="notes-ticker" ng-show="ntvm.notes.length">'+
							'<div layout="row">'+
								'<label class="notes-ticker-label">Notes</label>'+
								'<div class="notes-ticker-window", flex>'+
									'<div class="notes-ticker-overflow" layout="row" layout-align="start center" ng-class="{\'ticker\' : ntvm.isOverflowing()}">'+
										'<div class="note" ng-repeat="note in ntvm.notes | filter : note.deleted track by note.id">{{::note.note}}</div>'+
									'</div>'+
								'</div>'+
							'</div>'+
							'<md-divider></md-divider>'+
						'</div>',									
			link : function(scope, element, attr, ctrl) {

				var tickerNode = element[0].querySelector('.notes-ticker-overflow');

				ctrl.notes = [];

				scope.$watch(function() {
					return $routeParams.date;
				}, function(date) {

					var timestamp = $filter('date')(date, constants.timestamp);

					Note.getNotes(timestamp).then(function(response) {
						ctrl.notes = response.data.data.notes.now;
					});
				});


				ctrl.isOverflowing = function() {

					if (!tickerNode.childElementCount) {
						return;
					}

					var width = 0;

					_.forEach(tickerNode.children, function(item) {
						width += item.clientWidth;
					});

					return (width > tickerNode.clientWidth);
				};
			}
		};
	}
})();