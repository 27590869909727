(function() {
	"use strict";

	angular
		.module('diary')
		.factory('dateService', dateService);

	dateService.$inject = [];

	function dateService() {

		var getToday = function() {
			var today = new Date();
			var day = today.getDate();
			var month = today.getMonth();
			var year = today.getFullYear();
			var hours = today.getHours();
			var minutes = today.getMinutes();

			return new Date(year, month, day, hours, minutes);
		};

		var nextDay = function(today) {
			var clone = _.clone(today);
			return new Date(clone.setDate(clone.getDate() + 1));
		};

		var nextBusinessDay = function(today) {

			var interval;
			
			switch(today.getDay()) {
				case 5:
					interval = 3;
					break;
				case 6: 
					interval = 2;
					break;
				default:
					interval = 1;
			}

			var clone = _.clone(today);
			return new Date(clone.setDate(clone.getDate() + interval));
		};

		var previousDay = function(today) {
			var clone = _.clone(today);
			return new Date(clone.setDate(clone.getDate() - 1));
		};

		return {
			'getToday' : getToday,
			'nextDay' : nextDay,
			'nextBusinessDay' : nextBusinessDay,
			'previousDay' : previousDay
		};
	}

})();