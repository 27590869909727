(function() {
	"use strict";

	angular
		.module('diary')
		.filter('phone', phoneFilter);


	phoneFilter.$inject = [];


	function phoneFilter() {
		return function(input) {

			if (input === '') {
				return null;
			}
			
			var a,b,c;

			if (input.match(/^(08|011|01.1)/g)) {

                a = input.substring(0,5);
                b = input.substring(5,8);

	        // match 02x xxxx xxxx
	        } else if (input.match(/^02./g)) {

                a = input.substring(0,4);
                b = input.substring(4,8);

	        // match 0xxxx xxx xxx
	        } else {

                a = input.substring(0,5);
                b = input.substring(5,8);
	        }
            
            c = input.substring(8,11);

            console.log('converting phone', input, a+' '+b+' '+c);
	 
			return a+' '+b+' '+c;
		};
	}

})();