(function() {
	"use strict";

	angular
		.module('diary')
		.controller('NavigationController', NavigationController)
		.directive('navigation', NavigationDirective);

	NavigationController.$inject = ['Note', 'Auth', 'Modal', 'notificationService', 'constants', '$mdSidenav', '$location', '$filter', '$routeParams', '$mdDialog'];
	NavigationDirective.$inject = ['Auth', 'Token', 'urlService', '$mdSidenav', '$location', '$route'];

	function NavigationController(Note, Auth, Modal, notificationService, constants, $mdSidenav, $location, $filter, $routeParams, $mdDialog) {

		// --------------------------------------------
		// Init and Setup
		// --------------------------------------------

		var nav = this;

		nav.app = {
			companyName : constants.company.name,
			toolbar : false
		};

		

		// --------------------------------------------
		// UI / Filters
		// --------------------------------------------

		nav.toggleMenu = function() {
			$mdSidenav('menu').toggle();
		};

		nav.isActive = function(link) {
			return $location.path().indexOf(link) > -1;
		};

		nav.logout = function() {
			nav.app.user = null;

			Auth.logout();

			$mdDialog.cancel();

			$location.path('/login');
		};



		nav.isAdmin = function() {
			return Auth.isAdmin();
		};

		nav.isRestricted = function() {
			return Auth.isRestricted();
		};


		nav.changePassword = function(e) {
			Modal.show({
				'type' : 'changePassword',
				'event' : e,
				'data' : {
					'user' : nav.app.user
				}
			});
		};



		// --------------------------------------------
		// Links
		// --------------------------------------------

		nav.goto = function(area) {

			var url = '/' + area;

			if ($routeParams.date) {
				url += ('/' + $routeParams.date);
			}
			
			$location.path(url);
		};





		// --------------------------------------------
		// Notes count
		// --------------------------------------------

		nav.showNoteCount = function() {
			return nav.noteCount > 0;
		};

		nav.getNoteCount = function(date) {

			if (!date) {
				date = new Date();
			}

			var timestamp = $filter('date')(date, constants.timestamp);

			Note.getNotes(timestamp).then(function(response) {

				if (response.data.data.notes.now) {
					var obj = response.data.data.notes;
					nav.noteCount = obj.now.length + obj.next.length;
				}

			}, notificationService.requestFailed);
		};
	}

	function NavigationDirective(Auth, Token, urlService, $mdSidenav, $location, $route) {
		return {
			restrict : 'E',
			controller : 'NavigationController',
			controllerAs : 'nav',
			link : function(scope, element, attr, ctrl) {


				// --------------------------------------------
				// Route Changes
				// --------------------------------------------
				scope.$on('$locationChangeStart', function(e, nextUrl, currentUrl) {

					console.log('%%%%%%%%%%%% PAGE CHANGE %%%%%%%%%%%%', nextUrl);

					//Close the Sidenav
					$mdSidenav('menu').close();

					var nextLogin = nextUrl.indexOf("login");
					var currentLogin = currentUrl.indexOf("login");

					console.log('Authenicating user...');

					if (Auth.isAuthenticated()) {

						if (!Token.isPolling()) {
							
							//check if the user is not coming from Login
							if (currentLogin === -1) {
								Token.renew();
							}
    						
    						Token.start();
						}

					} else  {
						Token.stop();
						ctrl.logout();
					}
					
					ctrl.app.user = Auth.getUser();
					console.log("Current User:", ctrl.app.user);

					if (nextLogin > -1) {
						ctrl.app.toolbar = false;
					} else {
						ctrl.app.toolbar = true;
					}
				});
				
				scope.$on('$routeChangeSuccess', function(e, currentUrl, previousUrl) {
					ctrl.title = $route.current.title;

					if (currentUrl.$$route.originalPath.indexOf("login") === -1) {
						ctrl.getNoteCount(currentUrl.params.date);
					} else {
						ctrl.noteCount = 0;
					}
					
					//Update the URL history
					urlService.addURL($location.path());
				});
			}
		};
	}
	
})();