(function() {
	"use strict";

	angular
		.module('diary')
		.directive('inlineNotification', inlineNotification);

	inlineNotification.$inject = [];

	function inlineNotification() {
		return {
			restrict: 'E',
			replace: true,
			scope : {},
			templateUrl: '/partials/inlineNotification.tpl.html',
			link: function(scope, element, attrs) {

				scope.visible = false;

				scope.feedback = {
					title: null,
					message : null,
					errors: []
				};

				scope.$on('notification:on', function(e, body) {

					console.log('heard an inline notification', body);

					scope.visible = true;

					scope.feedback = body;
				});

				scope.close = function() {
					scope.visible = false;
				};
			}
		};
	}

})();