(function() {
	"use strict";

	angular
		.module('diary')
		.directive('title', TitleDirective);

	TitleDirective.$inject = ['$route'];

	function TitleDirective($route) {
		return {
			restrict : 'E',
			link : function(scope, element, attr) {

				var defaultTitle = element.text();

				scope.$on('$routeChangeSuccess', function() {
					element.text($route.current.title + ' | ' + defaultTitle);
				});
			}
		};
	}

})();