(function() {
	"use strict";

	angular
		.module('diary')
		.service('API', APIService);

	APIService.$inject = ['constants', '$http', '$q'];

	function APIService(constants, $http, $q) {

		var get = function(config) {
			return sync('GET', config);
		};

		var post = function(config) {
			return sync('POST', config);
		};

		var put = function(config) {
			return sync('PUT', config);
		};

		var patch = function(config) {
			return sync('PATCH', config);
		};

		var del = function(config) {
			return sync('DELETE', config);
		};

		var sync = function(type, config) {
			var pkg = _.assignIn({}, {
				'method' : type,
				'cache' : false,
				'timeout' : constants.timeout
			}, config);

			pkg.url = constants.api.host + constants.api.resource + config.url;

			console.warn('calling API', type, pkg);
			return $http(pkg);
		};

		return {
			'get' : get,
			'post' : post,
			'put' : put,
			'patch' : patch,
			'delete' : del
		};
	}

})();