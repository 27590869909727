(function() {
	"use strict";
	
	angular
		.module('diary')
		.controller('ModalController', ModalController);

	ModalController.$inject = ['Feedback', '$mdDialog'];

	function ModalController(Feedback, $mdDialog) {

		var mvm = this;

		mvm.validation = Feedback;

		mvm.cancel = function() {
			$mdDialog.cancel();
		};

		mvm.submit = function() {
			$mdDialog.hide();
		};

	}
	
})();