(function() {
	"use strict";

	angular
		.module('diary')
		.controller('CreateNoteController', CreateNoteController)
		.directive('createNoteButton', CreateNoteButton);

	CreateNoteController.$inject = ['Note', 'Feedback', '$mdDialog', '$routeParams'];
	CreateNoteButton.$inject = ['Note', 'notificationService', 'constants', '$mdDialog', '$filter', '$route'];

    function CreateNoteController(Note, Feedback, $mdDialog, $routeParams) {

    	var cnvm = this;

		cnvm.messages = Feedback;

		cnvm.dateToday = function() {
			cnvm.note.date = new Date();
		};

		cnvm.endDateToday = function() {
			cnvm.note.endDate = new Date();
		};

		cnvm.submit = function() {
			if (cnvm.note) {
				$mdDialog.hide(cnvm.note);
			}
		};

		cnvm.cancel = function() {
			$mdDialog.cancel();
		};

		cnvm.changeEndDate = function() {
			if (cnvm.note.date > cnvm.note.endDate) {
				cnvm.note.endDate = cnvm.note.date;
			}
		};

		cnvm.isTodayBeforeStartDate = function() {
			return (new Date()) < cnvm.note.date;
		};

		cnvm.calculateDateDiff = function() {

			if (cnvm.note.date && cnvm.note.endDate) {
				return Math.abs(moment(cnvm.note.endDate).diff(cnvm.note.date, 'days')) +1;
			} else if (cnvm.note.date) {
				return 1;
			} else {
				return 0;
			}
		};




		// --------------------------------------------
		// Model
		// --------------------------------------------

		cnvm.note = Note.getNoteModel();
		
	}

	function CreateNoteButton(Note, notificationService, constants, $mdDialog, $filter, $route) {
		return {
			restrict : 'A',
			link : function(scope, element, attr) {

				//move into a createNote directive
				var click = function(e) {

					e.preventDefault();

					$mdDialog.show({
						controller: 'CreateNoteController',
						controllerAs : 'cnvm',
						bindToController : true,
						templateUrl: '/partials/createNote.tpl.html',
						targetEvent: e,
						clickOutsideToClose:true,
						hasBackdrop: true,
						fullscreen: false,
						multiple : true
					})
					.then(function(note) {

						Note.create(note).then(function(response) {
								
							notificationService.createNotification({
								http: response,
								code: "n:created"
							});

							$route.reload();

						}, notificationService.requestFailed);	
					
					}, function() {
						//modal closed
					});
				};

				element.on('click', click);

				scope.$on('$destroy', function() {
					element.off('click', click);
				});
			}
		};
	}
})();