(function() {
	"use strict";

	angular
		.module('diary')
		.controller('AdminController', AdminController);

	AdminController.$inject = ['Config', 'Modal', 'User', 'notificationService', 'constants', '$q', '$route'];

	function AdminController(Config, Modal, User, notificationService, constants, $q, $route) {

		var avm = this;

		avm.config = {};
		avm.config.constants = constants;


		var promises = [
			Config.getUsers(),
			Config.getReferrers(),
		];

		$q.all(promises).then(function(response) {

			_.forEach(response, function(r, k) {
				var key = Object.keys(r.data.data)[0];
				avm.config[key] = r.data.data[key];

				console.log(key, avm.config[key]);
			});

		}, notificationService.requestFailed);


		// --------------------------------------------
		// Users
		// --------------------------------------------

		avm.addUser = function(e) {
			Modal.show({
				'type' : 'manageUser',
				'event' : e,
				'data' : {
					'add' : true
				}
			});
		};

		avm.editUser = function(e, user) {
			Modal.show({
				'type' : 'manageUser',
				'event' : e,
				'data' : {
					'edit' : true,
					'user' : user
				}
			});
		};

		avm.changePassword = function(e, user) {
			e.stopPropagation();
			Modal.show({
				'type' : 'changePassword',
				'event' : e,
				'data' : {
					'override' : true,
					'user' : user
				}
			});
		};

		avm.removeUser = function(e,user) {
			e.stopPropagation();
			User.remove(user.id).then(function(response) {

				notificationService.createNotification({
					http: response,
					code: 'a:updated'
				});

				$route.reload();
			});
		};
	}

})();