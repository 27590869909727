/*
------------------------------------
App Core
------------------------------------
Author:			Richard Marsh <info@remarsh.com>
Date: 			25th November 2015
*/

(function() {
    "use strict";

    var app = angular
        .module('diary', [
        	'ngMaterial',
        	'ngMessages',
        	'ngRoute',
        	'infinite-scroll'
        ])
        .config(DiaryConfig)
        .run(DiaryRun);

    DiaryConfig.$inject = ['$httpProvider', '$mdThemingProvider', '$mdDateLocaleProvider'];
    DiaryRun.$inject = ['$rootScope', '$locale'];

    function DiaryConfig($httpProvider, $mdThemingProvider, $mdDateLocaleProvider) {
        
        $httpProvider.interceptors.push('APIInterceptor');

        $mdThemingProvider.theme('default')
            .primaryPalette('deep-orange')
            .accentPalette('blue');

        //This breaks in 1.1.13
        $mdDateLocaleProvider.formatDate = function(date) {
            var m = moment(date);
            return m.isValid() ? m.format('ddd, DD-MM-YYYY') : '';
        };

        $mdDateLocaleProvider.parseDate = function(d) {
            d = d.replace(/(mon|tue|wed|thu|fri|sat|sun), /i, '');
            var m = moment(d, 'DD-MM-YYYY');
            return m.isValid() ? m.toDate() : moment(d).toDate();
        };
        
        $mdDateLocaleProvider.firstDayOfWeek = 1;
        $mdDateLocaleProvider.firstRenderableDate = new Date(2017, 0, 1);
    }

    function DiaryRun($rootScope, $locale) {
            
        console.log('%%%%%%%%%%%% APP STARTUP %%%%%%%%%%%%', $locale.id);

        $rootScope.$watch(function() {
            console.info('$DIGEST');
        });

        _.mixin({'titleCase' : function(str) {
            return str.replace(/\w\S*/g, function(txt){
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }});
    }

})();