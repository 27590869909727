(function() {
	"use strict";

	angular
		.module('diary')
		.factory('Token', TokenService);

	TokenService.$inject = ['API', 'Storage', 'notificationService', 'constants', '$interval', '$timeout', '$window'];

	function TokenService(API, Storage, notificationService, constants, $interval, $timeout, $window) {

		var timer;

		var attempts = 0;


		// --------------------------------------------
		// Get/Set
		// --------------------------------------------

		var get = function() {
			return Storage.get('token');
		};

		var set = function(token) {
			Storage.set('token', token);			
		};

		var clear = function() {
			Storage.destroy('token');
		};

		var parse = function(token) {

			if (!token) {
				return false;
			}

			var claim = token.split('.')[1];

			if (!claim) {
				return false;
			}
			
			var base64 = claim.replace('-', '+').replace('_', '/');

			try {
				return JSON.parse($window.atob(base64));
			}
			catch(e) {
				return false;
			}
		};




		// --------------------------------------------
		// Polling
		// --------------------------------------------

		var start = function() {
			console.log('Start Token polling...');
			timer = $interval(renew, constants.tokenRenew);
		};

		var stop = function() {
			$interval.cancel(timer);
			timer = null;
			console.log('Stop Token polling...', timer);
		};

		var isPolling = function() {
			return timer;
		};

		var renew = function() {
        	
        	console.info('Renewing Token...');

			API.get({
				url: '/token',
			}).then(function(response) {

				var header = response.headers('token');

				if (!header) {
					retry(response);
					return;
				}

				var token = parse(header);

				if (!token) {
					retry(response);
				} else {
					Storage.set('token', header);
				}

			}, function(response) {
				retry(response);
			});
		};

		var retry = function(response) {			
			if (attempts < 3) {
				attempts++;
				$timeout(renew, 60000, false);
			} else {
				attempts = 0;
				stop();
				notificationService.createNotification({
					http: response,
					code: "g:failedtoken"
				});
				clear();
			}
		};

		return {
			'get' : get,
			'set' : set,
			'clear' : clear,
			'parse' : parse,
			'start' : start,
			'stop' : stop,
			'renew' : renew,
			'isPolling' : isPolling
		};

	}
	
})();