(function() {
	"use strict";

	angular
		.module('diary')
		.factory('Modal', ModalService);

	ModalService.$inject = ['ModalTypes', '$document', '$mdDialog', '$mdMedia', '$q'];

	function ModalService(ModalTypes, $document, $mdDialog, $mdMedia, $q) {

		var confirm = function(key) {

			var q = $q.defer();
			
			if (!key) {
				q.reject();
				return q.promise;
			}

			var config = ModalTypes.get(key);

			if (typeof config !== 'object') {
				q.reject();
				return q.promise;
			}

			var confirm = $mdDialog.confirm(config);

			$mdDialog.show(confirm).then(function() {
				q.resolve();
			}, function() {
				q.reject();
			});

			return q.promise;
		};

		var alert = function(key) {

			var q = $q.defer();

			if (!key) {
				q.reject();
				return q.promise;
			}

			var config = ModalTypes.get(key);

			if (typeof config !== 'object') {
				q.reject();
				return q.promise;
			}

			var alert = $mdDialog.alert(config);

			$mdDialog.show(alert).finally(function() {
				q.resolve();
			});

			return q.promise;			
		};
		
		var show = function(options) {

			if (options && !options.type) {
				return;
			}

			var modal = ModalTypes.get(options.type);

			if (typeof modal === 'object') {

				console.log('modal options', options);
				console.log('modal type', modal);

				var config = _.assign(modal, {
					parent : angular.element($document[0].body),
					fullscreen : $mdMedia('xs'),
					bindToController : true,
					controllerAs : 'mvm',
                    autoWrap : true
				});

				if (options.event) {
					config.targetEvent = options.event;
				}

				if (options.data) {
					config.locals = {
						'data' : options.data
					};
				}

				if (!options.submit) {
					options.submit = angular.noop;
				}

				if (!options.cancel) {
					options.cancel = angular.noop;
				}

				$mdDialog.show(config).then(options.submit, options.cancel);
			}
		};


		return {
			'confirm' : confirm,
			'alert' : alert,
			'show' : show
		};
	}	
})();