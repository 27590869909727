(function() {
	"use strict";

	angular
		.module('diary')
		.factory('Address', AddressService);

	AddressService.$inject = ['API'];

	function AddressService(API) {

		var get = function(postcode) {
			return API.get({
				url: '/address/' + postcode
			});
		};

		return {
			'get' : get
		};
	}
	
})();