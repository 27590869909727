(function() {
	"use strict";

	angular
		.module('diary')
		.filter('address', AddressFilter);

	AddressFilter.$inject = [];

	function AddressFilter() {
		return function(address) {
			return address.premise + ', ' + address.thoroughfare + ', ' + _.titleCase(address.post_town) + ', ' + address.county + ', ' + address.postcode;
		};
	}
	
})();