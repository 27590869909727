(function() {
	"use strict";

    angular
        .module('diary')
        .service('notificationService', notificationService);

    notificationService.$inject = ['constants', '$rootScope', '$mdToast', '$mdDialog', '$timeout'];

    function notificationService(constants, $rootScope, $mdToast, $mdDialog, $timeout) {

		var notifications = [];

		return {
			createNotification : function(notification) {

				console.log('CREATING NOTIFICATION!', notification);
				
				var body = getNotificationContent(notification);

				fireNotification(body);
				
			},
			fireNotification : fireNotification,
			deferNotification : function(notification) {

				var body = getNotificationContent(notification);

				if (body) {

					notifications.push(body);
				}
			},
			getDeferredNotifications : function() {
				return notifications;
			},
			requestFailed : function(response) {

				console.log('HTTP request failed', response);

				if (response.status === 401) {
					return;
				}

				var body = getNotificationContent({
					http: response,
					code: null
				});
				
				fireNotification(body);
			},
			loginFailed : function(response) {

				console.log('Login failed');

				var body = getNotificationContent({
					http: response,
					code: null
				});

				if (response.status === 401) {
					body.format = constants.notification.inline;
				}
				
				fireNotification(body);
			}
		};

		function clearNotification(notification) {
			_.remove(notifications, notification);
		}

		function fireNotification(notification) {

			clearNotification(notification);

			if (notification.format === constants.notification.toast) {

				fireToast(notification);
			}

			if (notification.format === constants.notification.modal) {

				fireModal(notification);
			}

			if (notification.format === constants.notification.inline) {

				fireInline(notification);
			}
		}

		function fireModal(body) {

			console.log('modal notification', body);

			$mdDialog.show({
				templateUrl: '/partials/notification.tpl.html',
				fullscreen: false,
				clickOutsideToClose: !body.blocking,
				hasBackdrop: body.blocking,
				locals: {
					'modal' : body
				},
				controller : function($scope, modal, $mdDialog) { 
	    			$scope.modal = modal;
	    			$scope.runEvent = $mdDialog.hide;
	  			}
			})
			.then(function(answer) {
				
				if (answer) {
					switch(answer) {
						case 'reload':
							//$route.reload();
							break;
						case 'back':
							//$window.history.back();
							break;
						default:
							break;
					}
				}

			}, function() {
				
				console.log('closed the notification modal');
			});
		}

		function fireToast(body) {

			console.log('toast notification', body);

			$mdToast.show(
				$mdToast.simple()
					.textContent(body.title)
					.position('top right')
					.hideDelay(3000)
		    );
		}

		function fireInline(body) {

			console.log('inline notification', body);
			
			$timeout(function() {
				$rootScope.$broadcast('notification:on', body);
			}, 0);
		}

		function getNotificationCode(status) {

			switch(status) {
				case 500:
					return 'g:server';

				case 404:
					return 'g:notfound';

				case 403:
					return 'g:permissions';

				case 401:
					return 'g:unauthorized';

				case 400:
					return 'g:badrequest';

				default:
					return 'g:timeout';
			}
		}

		function getNotificationContent(notification) {

			if (_.isNull(notification.code) || _.isUndefined(notification.code)) {
					
				notification.code = getNotificationCode(notification.http.status);
			}

			switch(notification.code) {


				// --------------------------------------------
				// Global Notifications
				// --------------------------------------------

				case 'g:timeout':
					return {
						title: 'Unable to contact server',
						message: 'Timed-out waiting for the API to respond. Please try again.',
						_links: [
							{ href : '', label: 'OK', click : null}
						],
						type: 'error',
						blocking: true,
						format: constants.notification.modal
					};

				case 'g:server':
					return {
						title: 'Server error',
						message: 'The API was unable to process the data and failed. Please contact support or try again.',
						_links: [
							{ href : '', label: 'OK', click : null},
						],
						errors: (notification.http.data.data) ? notification.http.data.data.errors : [],
						type: 'error',
						blocking: true,
						format: constants.notification.modal
					};

				case 'g:unauthorized':
					return {
						title: 'Unauthorized user',
						message: notification.http.data.data.message,
						_links: [
							{ href : '', label: 'OK', click : 'reload'}
						],			
						type: 'error',
						blocking: true,
						format: constants.notification.modal
					};
				case 'g:failedtoken':
					return {
						title : 'Authentication server offline',
						message: 'Unable to renew session token. Please Logout and start again.',
						_links : [
							{href : '#!/login', label : 'Logout', click : ''}
						],
						type : 'error',
						blocking : true,
						format : constants.notification.modal
					};

				case 'g:permissions':
					return {
						title: 'Limited Access',
						message: notification.http.data.data.message,
						_links: [
							{ href : '', label: 'Try again', click : 'reload'}
						],			
						type: 'error',
						blocking: true,
						format: constants.notification.modal
					};

				case 'g:badrequest':
					//message: 'The data returned by the API was corrupt. Do you want to re-try?',
					return {
						title: 'Oops! Problem.',
						message: notification.http.data.data.message,
						_links: [
							{ href : '', label: 'OK', click : ''},
						],
						errors: notification.http.data.data.errors,
						type: 'error',
						blocking: true,
						format: constants.notification.modal
					};

				case 'g:exit': 
					return {
						title: 'Do you want to exit?',
						message: 'Any changes that you have made so far will be lost. Are you sure you want to exit?',
						_links: [
							{ href : notification.url, label: 'Exit', click : 'exit'},
							{ href : '', label: 'Cancel'}
						],			
						type: 'info',
						blocking: false,
						format: constants.notification.modal
					};

				case 'g:notfound':
					return {
						title: 'Record not found',
						message: 'Sorry, but no record exists with that ID. Please check the URL and try again.',
						_links: [
							{ href : '', label: 'OK', click : ''}
						],			
						type: 'error',
						blocking: true,
						format: constants.notification.modal
					};


				// --------------------------------------------
				// Customer Notifications
				// --------------------------------------------
				
				case 'c:updated':
					return {
						title: 'Customer updated',
						message: null,
						_links: [],			
						type: 'success',
						blocking: false,
						format: constants.notification.toast
					};


				// --------------------------------------------
				// Job Notifications
				// --------------------------------------------
				
				case 'j:created':
					return {
						title: 'Job added',
						message: null,
						_links: [],			
						type: 'success',
						blocking: false,
						format: constants.notification.toast
					};

				case 'j:deleted':
					return {
						title: 'Job deleted',
						message: null,
						_links: [],			
						type: 'success',
						blocking: false,
						format: constants.notification.toast
					};

				case 'j:updated':
					return {
						title: 'Job updated',
						message: null,
						_links: [],
						type: 'success',
						blocking: false,
						format: constants.notification.toast
					};

				case 'j:duplicated':
					return {
						title: 'Job duplicated',
						message: null,
						_links: [],
						type: 'success',
						blocking: false,
						format: constants.notification.toast
					};


				// --------------------------------------------
				// Admin
				// --------------------------------------------
			
				case 'a:updated':
					return {
						title: 'System updated',
						message: null,
						_links: [],
						type: 'success',
						blocking: false,
						format: constants.notification.toast
					};
				case 'a:changePassword':
					return {
						title: 'Password Changed',
						message: null,
						_links: [],
						type: 'success',
						blocking: false,
						format: constants.notification.toast
					};


				// --------------------------------------------
				// Notes Notifications
				// --------------------------------------------
				
				case 'n:created':
					return {
						title: 'Note added',
						message: null,
						_links: [],			
						type: 'success',
						blocking: false,
						format: constants.notification.toast
					};

				// --------------------------------------------
				// Search Notifications
				// --------------------------------------------
				
				case 's:noparams':
					return {
						title: 'Invalid Search',
						message: 'Please provide at least one search parameter, such as "date".',
						_links: [
							{ href : '', label: 'OK', click : ''}
						],			
						type: 'error',
						blocking: false,
						format: constants.notification.modal
					};

				case 's:sendtodevice':
					return {
						title: 'Job sent to device',
						message: null,
						_links: [],
						type: 'success',
						blocking: false,
						format: constants.notification.toast
					};

				default:
					return {};
			}
		}
	}

})();