(function() {
	"use strict";

    angular
        .module('diary')
        .factory('Customer', CustomerService);

    CustomerService.$inject = ['API'];

    function CustomerService(API) {

		var getCustomerModel = function() {
			return _.cloneDeep({
				id : null,
				prefix : null,
				first_name : null,
				last_name : null,
				full_name : null,
				company : null,
				email: null,
				phone: null,
				mobile: null,
				address: {
					house_number: null,
					street: null,
					city: null,
					postcode: null
				}
			});
		};

		var getCustomers = function() {
			return API.get({
				url: '/customers'
			});
		};

		var getById = function(id) {
			return API.get({
				url: '/customer/' + id
			});
		};

		var optionalKeys = ['prefix', 'mobile', 'phone', 'email', 'first_name', 'company'];

		var prepare = function(customer) {
			_.each(optionalKeys, function(prop, i) {
				if (_.isEmpty(customer[prop])) {
					customer[prop] = null;
				}
			});

			if (customer.full_name) {
				delete customer.full_name;
			}

			return customer;
		};

		var hasMinimumProperties = function(customer) {
			return !_.isEmpty(_.omit(customer, ['id', 'deleted'].concat(optionalKeys)));
		};

		var update = function(body) {
			return API.put({
				data: {
					'customer': body
				},
				url: '/customer/' + body.id
			});
		};

		var create = function(body) {
			return API.post({
				data: {
					'customer': body
				},
				url: '/customer'
			});
		};

		

		return {
			'getCustomerModel' : getCustomerModel,
			'getCustomers' : getCustomers,
			'getById' : getById,
			'prepare' : prepare,
			'hasMinimumProperties' : hasMinimumProperties,
			'update' : update,
			'create' : create
		};
	}

})();