(function() {
    "use strict";

    angular
        .module('diary')
        .directive('ngEnter', ngEnter);

    ngEnter.$inject = [];

    function ngEnter() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {

                var enter = function(e) {

                    if (e.which === 13) {
                        scope.$apply(function() {
                            scope.$eval(attrs.ngEnter);
                        });

                        e.preventDefault();
                    }
                };

                element.on('keydown keypress', enter);

                scope.$on('$destroy', function() {
                    element.off('keydown keypress', enter);
                });
            }
        };
    }

})();