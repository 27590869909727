(function() {
	"use strict";

	angular
		.module('diary')
		.service('Auth', AuthService);

	AuthService.$inject = ['Token', 'API', '$httpParamSerializer', 'constants'];

	function AuthService(Token, API, $httpParamSerializer, constants) {
		
		var self = this;

		self.user = null;

		self.isAdmin = function() {
			return self.user && self.user.level === constants.permissions.admin;
		};

		self.isRestricted = function() {
			return self.user && self.user.level === constants.permissions.restricted;
		};

		self.getUser = function() {

			if (self.user) {

				return self.user;

			} else {

				var token = Token.get();

				if (!token) {
					return false;

				} else {
					
					var claim = Token.parse(token);
					
					return (claim) ? claim.user : false;
				}				
			}
		};

		self.setUser = function(user) {
			self.user = user;
		};

		self.isAuthenticated = function() {
		
			var token = Token.get();
			
			if (!token) {
				return false;
			}

			var params = Token.parse(token);

			if (!params) {
				return false;
			}

			if (Math.round(new Date().getTime() / 1000) <= params.expiry) {
				self.setUser(params.user);
				return true;
			} else {
				return false;
			}
		};

		self.login = function(credentials) {
			return API.post({
				data: $httpParamSerializer(credentials),
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				url: '/login'
			});
		};

		self.logout = function() {

			console.log('%%%%%%%%%%%% LOGOUT %%%%%%%%%%%%');

			self.setUser(null);

			Token.clear();
		};
	}

})();