(function() {
	"use strict";
	
	angular
		.module('diary')
		.controller('ForgotPasswordModalController', ForgotPasswordModalController);

	ForgotPasswordModalController.$inject = ['Feedback', '$mdDialog'];

	function ForgotPasswordModalController(Feedback, $mdDialog) {

		var mvm = this;

		mvm.validation = Feedback;

		mvm.cancel = function() {
			$mdDialog.cancel();
		};

		mvm.submit = function() {
			$mdDialog.hide();
		};

	}
	
})();