(function() {
	"use strict";

    angular
        .module('diary')
        .factory('User', UserService);

    UserService.$inject = ['API'];

    function UserService(API) {

    	//get

    	//getbyid
        var getModel = function() {
            return _.cloneDeep({
                id : null,
                first_name: null,
                last_name : null,
                username : null,
                password: null,
                assignable : null,
                level : null
            });
        };

    	//add
        var add = function(user) {
            return API.post({
                url : '/user',
                data : {
                    'user' : user
                }
            });
        };

    	//edit
        var edit = function(user) {
            return API.put({
                url : '/user/' + user.id,
                data : {
                    'user' : user
                }
            });
        };

    	//remove
        var remove = function(id) {
            return API.patch({
                url : '/user/' + id
            });
        };

    	//changepassword
        var changePassword = function(user) {
            return API.put({
                url : '/user/' + user.id + '/password',
                data : {
                    'user' : user
                }
            });
        };

        //overridepassword
        var overridePassword = function(user) {
            return API.put({
                url : '/user/' + user.id + '/overridepassword',
                data : {
                    'user' : user
                }
            });
        };

		return {
            'getModel' : getModel,
			'add' : add,
            'edit' : edit,
            'remove' : remove,
            'changePassword' : changePassword,
            'overridePassword' : overridePassword
		};
	}

})();