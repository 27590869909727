(function() {
	"use strict";

	angular
		.module('diary')
		.filter('history', HistoryFilter);

	HistoryFilter.$inject = ['constants', '$filter'];

	function HistoryFilter(constants, $filter) {
		return function(input, parameter, config) {
			switch(parameter) {
				case 'flagged':
				case 'deleted':
					return (parseInt(input)) ? 'true' : 'false';
				case 'status' :
					return _.find(config.statuses, function(status) {
						return status.id === parseInt(input, 10);
					}).label;
				case 'user':
					var user = _.find(config.users, function(user) {
						return user.id === parseInt(input, 10);
					});
					return (user) ? user.full_name : constants.empty.user;
				case 'appointment':
					return _.find(config.appointments, function(appointment) {
						return appointment.id === parseInt(input, 10);
					}).label;
				case 'referrer':
					var ref =  _.find(config.referrers, function(referrer) {
						return referrer.id === parseInt(input, 10);
					});
					return (ref) ? ref.label : constants.empty.referrer;
				case 'date' : 
					return $filter('date')(input, constants.date);
				case 'timeofbooking' :
					var timeOfBooking = new Date(input);
					return $filter('date')(timeOfBooking, 'short');
				default:
					return input;
			}
		};
	}

})();