(function() {
	"use strict";

	angular
		.module('diary')
		.controller('ManageUserModalController', ManageUserModalController);

	ManageUserModalController.$inject = ['User', 'notificationService', 'Feedback', 'data', '$mdDialog', '$route'];

	function ManageUserModalController(User, notificationService, Feedback, data, $mdDialog, $route) {

		var modalvm = this;

		modalvm.validation = Feedback;


		if (data.user) {
			modalvm.user = data.user;			
		} else {
			modalvm.user = User.getModel();
		}

		modalvm.add = data.add;
		modalvm.edit = data.edit;

		modalvm.save = function(user) {

			delete modalvm.message;

			var promise = (modalvm.add) ? User.add(user) : User.edit(user);

			promise.then(function(response) {

					$mdDialog.hide();
					
					notificationService.createNotification({
						http: response,
						code: 'a:updated'
					});

					$route.reload();

			}, function(response) {
				modalvm.message = {
					text : response.data.data.message,
					errors : response.data.data.errors
				};
			});
		};

		modalvm.cancel = function() {
			$mdDialog.cancel();
		};
	}

})();