(function() {
    "use strict";

    angular
        .module('diary')
        .directive('mq', mediaQuery);

    mediaQuery.$inject = ['$mdMedia', '$window'];

    function mediaQuery($mdMedia, $window) {
		return {
			restrict: 'A',
			link: function(scope, element, attr) {
				
				var breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
				var klass = '';

				var setClass = function() {

					element.removeClass(klass);
					
					_.each(breakpoints, function(b,i) {
						if ($mdMedia(b)) {
							klass = b;
						}
					});

					console.log('Media query', klass);
					
					element.addClass(klass);
				};

				setClass();

				angular.element($window).on('resize', setClass);

				scope.$on('$destroy', function() {
					angular.element($window).off('resize', setClass);
				});
			}
		};
	}

})();