(function() {
	"use strict";

    angular
        .module('diary')
        .directive('customer', CustomerDirective)
        .controller('CustomerController', CustomerController);

    CustomerController.$inject = ['Customer', 'Search', 'Feedback', 'notificationService', '$q'];
    CustomerDirective.$inject = ['Customer'];

    function CustomerController(Customer, Search, Feedback, notificationService, $q) {


		// --------------------------------------------
		// Initialisation & Setup
		// --------------------------------------------

		var cvm = this;

		cvm.app = {
			validation: Feedback
		};




		// --------------------------------------------
		// Search
		// --------------------------------------------

		cvm.queryCustomersByName = function(keyword) {
			var q = $q.defer();

			if (!keyword) {
				q.reject({'message' : 'No keyword specified'});
			}
			
			Search.customers({'customer_name' : keyword}).then(function(response) {
				q.resolve(response.data.data.results);
			}, function(response) {
				q.reject(response.data.data.message);
			});

			return q.promise;
		};

		cvm.queryCustomersByPostcode = function(keyword) {
			var q = $q.defer();

			if (!keyword) {
				q.reject({'message' : 'No keyword specified'});
			}
			
			Search.customers({'postcode' : keyword}).then(function(response) {
				q.resolve(response.data.data.results);
			}, function(response) {
				q.reject(response.data.data.message);
			});

			return q.promise;
		};

		cvm.selectCustomer = function(customer) {

			cvm.customer = (customer) ? customer : Customer.getCustomerModel();

			console.log("Setting customer", cvm.customer);

			if (cvm.onAddressSelect) {
				cvm.onAddressSelect({address : cvm.customer.address});
			}
		};

		cvm.reset = function() {
			cvm.search.name = null;
			cvm.customer = Customer.getCustomerModel();
			add = false;
			cvm.cancel();
		};


		// --------------------------------------------
		// Add mode
		// --------------------------------------------

		var add = false;

		cvm.add = function() {
			add = true;			
		};

		cvm.isAdding = function() {
			return add;
		};

		cvm.cancelAdding = function() {
			add = false;
			cvm.customer = Customer.getCustomerModel();
			cvm.cancelOverride();
		};


		// --------------------------------------------
		// Edit mode
		// --------------------------------------------

		var edit = false;

		cvm.isEditing = function() {
			return edit;
		};

		cvm.edit = function() {
			edit = true;
			cvm.onEdit({'flag' : true});
		};

		cvm.cancel = function() {
			edit = false;
			
			if (cvm.onEdit) {
				cvm.onEdit({'flag' : false});				
			}
		};


		// --------------------------------------------
		// Address
		// --------------------------------------------

		var addressOverride = false;

		cvm.isAddressOverride = function() {
			return addressOverride;
		};

		cvm.overrideAddress = function() {
			addressOverride = true;
		};

		cvm.cancelOverride = function() {
			addressOverride = false;
		};

		cvm.selectAddress = function(address) {
			if (address) {
				cvm.customer.address = address;
				addressOverride = true;

				if (cvm.onAddressSelect) {
					cvm.onAddressSelect({address : cvm.customer.address});
				}
			}
		};








		// --------------------------------------------
		// Save/Update HTTP
		// --------------------------------------------

		cvm.save = function() {

			var customer = _.cloneDeep(cvm.customer);

			if (!Customer.hasMinimumProperties(customer)) {

				//TODO: add type
				notificationService.createNotification({
					http: null,
					code: "c:noparams"
				});

				return;
			}

			var tasks = [];

			customer = Customer.prepare(customer);

			tasks.push(Customer.update(customer));
			
			$q.all(tasks).then(function(response) {

				notificationService.createNotification({
					http: response,
					code: (customer.id) ? "c:updated" : "c:created"
				});

				cvm.cancel(true);

				if (cvm.onSave) {
					cvm.onSave();
				}

			}, notificationService.requestFailed);
		};
	}

	function CustomerDirective(Customer) {
		return {
			restrict : 'A',
			scope : false,
			controller : 'CustomerController',
			controllerAs : 'cvm',
			bindToController : {
				'customer' : '=',
				'onEdit' : '&customerEdit',
				'onSave' : '&customerSave',
				'onAddressSelect' : '&customerAddressSelect'
			},
			compile : function(tElement, tAttr) {
				return {
					'post' : function(scope, element, attr, ctrl, transclude) {

						if (!ctrl.customer) {
							ctrl.customer = Customer.getCustomerModel();							
						}
					}
				};
			}
		};
	}

})();