(function() {
	"use strict";

	angular
		.module('diary')
		.directive('loading', LoadingDirective);

	LoadingDirective.$inject = ['$timeout'];

	function LoadingDirective($timeout) {
		return {
			restrict : 'E',
			replace: true,
			template : 
				'<div class="loading" ng-if="loading">' +
					'<md-progress-linear md-mode="indeterminate"></md-progress-linear>' +
				'</div>',
			link : function(scope, element, attr) {

				scope.loading = false;

				scope.$on('loading:off', function(e) {
					$timeout(function() {
						scope.loading = false;
					}, 333);
				});

				scope.$on('loading:on', function(e) {
					scope.loading = true;
				});
			}
		};
	}

})();