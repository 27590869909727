(function() {
	"use strict";

	angular
		.module('diary')
		.directive('passwordMatch', PasswordMatchDirective);

	PasswordMatchDirective.$inject = [];

	function PasswordMatchDirective() {
		return {
			require: "ngModel",
	        link: function(scope, element, attr, ctrl) {

	        	var confirmPassword;
	             
	            ctrl.$validators.passwordmatch = function(modelValue, viewValue) {
	                return (modelValue || viewValue) === confirmPassword;
	            };

	            scope.$watch(attr.passwordMatch, function(password) {
	            	confirmPassword = password;
	            	ctrl.$validate();
	            });
	        }
		};
	}
	
})();