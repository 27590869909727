(function() {
	"use strict";
	
	angular
		.module('diary')
		.factory('Push', PushService);

	PushService.$inject = ['API'];

	function PushService(API) {

		var getContacts = function() {
			return API.get({
				url : '/push/contacts',
				cache : true
			});
		};

		var create = function(body) {
			return API.post({
				url : '/push',
				data: {
					'notification' : body
				},
			});
		};

		return {
			'getContacts' : getContacts,
			'create' : create
		};
	}
	
})();