/*
------------------------------------
Configuration
------------------------------------

api: 		URL to the backend webservice
timeout: 	Number of milliseconds to wait for API response.
date: 		Default date format for $filter
time: 		Default time format for $filter
datetime: 	Default datetime object formation for $filter


TODO: Refactor to $get this from DB via JSON web service.
*/

(function() {
    "use strict";

    angular
        .module('diary')
        .constant('constants', {
			'company' : {
				'name': 'The Aerial Man',
				'description' : 'TV Aerial Installation Specialist'
			},
			'api' : {
				'host' : 'http://localhost',
				'resource' : '/diary2.0/api'
			},
			'tokenRenew' : (12 * 60 * 1000), //12minutes, giving 3 minutes to reattempt if fails
			'timeout': 20000,
			'locale': 'en-gb',
			'date' : 'EEE, d MMM y',
			'time' : 'HH:mm',
			'dateTime' : 'medium',
			'timestamp' : 'yyyy-MM-ddTHH:mm:ssZ',
			'diaryDate' : 'yyyy-MM-dd',
			'notification' : {
				'toast' : 'toast',
				'modal' : 'modal',
				'inline' : 'inline'
			},
			'boolean' : [
				{
					'label': 'No',
					'value': false
				}, 
				{
					'label': 'Yes',
					'value': true 
				}
			],
			'empty' : {
				'user' : 'Unassigned',
				'referrer' : 'None'
			},
			'permissions' : {
				'admin' : 1,
				'standard' : 2,
				'restricted' : 3
			},
			'take' : [
				{
					'label' : '100',
					'value' : 100
				},{
					'label' : '500',
					'value' : 500
				},{
					'label' : '1000',
					'value' : 1000
				}
			]
		});

})();