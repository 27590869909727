(function() {
	"use strict";

    angular
        .module('diary')
        .factory('Status', StatusService);

    StatusService.$inject = ['API'];

    function StatusService(API) {

    	//get

    	//add

    	//edit

    	//remove

		return {
			
		};
	}

})();