(function() {
	"use strict";

    angular
        .module('diary')
        .controller('SearchController', SearchController);

    SearchController.$inject = ['$routeParams', 'Auth', '$filter', '$q', '$location', '$mdMedia', '$mdSidenav', 'mode', 'constants', 'Feedback', 'dateService', 'Config', 'filterService', 'Search', 'Customer', 'jobService', 'notificationService', 'Modal', '$mdDialog'];

    function SearchController($routeParams, Auth, $filter, $q, $location, $mdMedia, $mdSidenav, mode, constants, Feedback, dateService, Config, filterService, Search, Customer, jobService, notificationService, Modal, $mdDialog) {

		// --------------------------------------------
		// Initialisation & Setup
		// --------------------------------------------

		var svm = this;

		svm.app = {
			'constants' : constants,
			'mode' : mode,
			'media' : $mdMedia,
			'validation' : Feedback
		};

		svm.refine = {
			'status' : ''
		};

		svm.refine.deleted = (mode.search) ? true : false;

		svm.count = {
			'total' : 0
		};

		svm.results = [];

		svm.filter = filterService.getNewFilter();

		svm.isRestricted = function() {
			return Auth.isRestricted();
		};

		var configs = [
			Config.getUsers(),
			Config.getStatuses()
		];

		if (mode.diary) {
			svm.loading = true;
			var urlDate = Date.parse($routeParams.date + 'T00:00');
			svm.filter.date = (_.isFinite(urlDate)) ? new Date(urlDate) : new Date();

			if (svm.isRestricted()) {
				svm.filter.assigned_to = Auth.user.id;
			}
			
		} else {
			svm.loading = false;
		}		
		
		svm.scroll = {
			'disabled' : true,
			'next' : function() {
				svm.filter.skip++;
				svm.runSearch();
			},
			'reset' : function() {
				svm.filter.skip = 0;
			}
		};		

		$q.all(configs).then(function(response) {

			svm.config = {};

			//http://stackoverflow.com/questions/16617259/wait-until-scope-variable-is-loaded-before-using-it-in-the-view-in-angular-js
			_.forEach(response, function(r, k) {
				var key = Object.keys(r.data.data)[0];
				svm.config[key] = r.data.data[key];

				console.log(key, svm.config[key]);
			});

			var assignable_users = _.filter(svm.config.users, function(item) {
				return !item.deleted && item.assignable;
			});

			svm.config.assignable_users = _.orderBy(assignable_users, 'first_name');

			var notifications = notificationService.getDeferredNotifications();

			console.log('Deferred Notifications', notifications);

			if (notifications.length) {
				
				_.each(notifications, function(n, k) {
					notificationService.fireNotification(n);
				});
			}

		}, notificationService.requestFailed);


		// --------------------------------------------
		// UI Controls
		// --------------------------------------------

		svm.sendToDevice = function(e, data) {

			e.stopPropagation();

			Modal.show({
				'event' : e,
				'type' : 'sendToDevice',
				'data' : {
					'job' : data
				}
			});
		};

		svm.toggleMenu = function() {
			$mdSidenav('filter').toggle();
		};

		svm.toggleFilterSettings = function() {
			svm.settings = !svm.settings;
		};

		svm.getResultsCount = function() {

			if (!svm.results.length) {
				return '0 jobs found';
			}

			if (svm.refine.deleted) {
				if (svm.results.length === 1) {
					return svm.results.length + ' job found';
				} else {
					return svm.results.length + ' jobs found';
				}
			}

			if (!svm.refine.deleted) {

				var deleted = _.filter(svm.results, function(result) {
					return result.deleted;
				});
				
				if (svm.results.length === 1 && deleted.length) {
					return svm.results.length + ' job found (deleted)';
				} else {

					if (deleted.length) {
						return svm.results.length + ' jobs found (' + deleted.length + ' deleted)';
				} else {
						return svm.results.length + ' jobs found';
					}

				}
			}
		};

		svm.queryCustomers = function(keyword) {

			var q = $q.defer();

			if (!keyword) {
				q.reject({'message' : 'No keyword specified'});
			}
			
			Search.customers({'customer_name' : keyword}).then(function(response) {
				q.resolve(response.data.data.results);
			}, function(response) {
				q.reject(response.data.data.message);
			});

			return q.promise;
		};
		
		svm.selectCustomer = function(customer) {
			if (customer && (customer.id !== svm.filter.customer)) {
				svm.filter.customer = customer.id;
				svm.selectedCustomer = customer;
				svm.runSearch();
			}
		};

		svm.clearCustomer = function() {

			svm.selectedCustomer = null;
			svm.search.customer = null;

			svm.clearInput('customer');
		};

		svm.clearQuickFilter = function() {
			svm.app.keyword = null;
		};

		svm.clearInput = function(input) {
			
			svm.filter[input] = null;
			
			if (filterService.hasMinimumParams) {
				svm.runSearch();
			} else {
				svm.results = [];
			}
		};

		svm.clearFilter = function() {
			console.log('reset filter');
			svm.filter = filterService.getNewFilter();

			if (filterService.hasMinimumParams) {
				svm.runSearch();
			} else {
				svm.results = [];
			}
		};

		svm.clearStatus = function() {
			svm.refine.status = null;
		};

		svm.setRefineStatus = function(status) {
			svm.refine.status = status;
		};

		//Only applies for flagged / deleted
		svm.updateJob = function(e, job, val, prop) {

			e.stopPropagation();
			
			var original = job[prop];

			if (val !== original) {

				//Update the view model
				job[prop] = val;
				
				//Get the ID of the selected object
				if (_.isObject(val)) {
					val = val.id;
				}

				//Otherwise kill the value
				if (_.isUndefined(val)) {
					val = null;
				}
				
				var packet = {};
				packet[prop] = val;

				console.log('Updating Job', packet);
				
				jobService.patchJob(job.id, packet).then(function(response) {

					notificationService.createNotification({
						http: response,
						code: 'j:updated'
					});
				
				}, function(response) {

					//rollback
					job[prop] = original;

					//throw error warning
					notificationService.createNotification({
						http:response,
						code: null
					});
				});
				
			}
		};

		svm.deleteJob = function(e, job,val, prop) {

			e.stopPropagation();

			if (job.deleted) {
				
				svm.updateJob(e, job, val, prop);

			} else {				
				
				var confirm = $mdDialog.confirm()
					.title('Are you sure you want to delete this job?')
					.textContent('You can undo this at anytime.')
					.targetEvent(e)
					.ok('Delete')
					.cancel('Cancel');

				$mdDialog.show(confirm).then(function() {
					svm.updateJob(e, job, val, prop);
				}, function() {

				});
			}
		};

		svm.showDeleted = function() {
			if (svm.results.length) {
				svm.count = Search.countJobStatuses(svm.results, svm.refine.deleted);
			}
		};

		svm.dateChange = function(date) {
			$location.path('/diary/' + $filter('date')(svm.filter.date, constants.diaryDate));
		};

		svm.datePrevious = function() {
			var date = dateService.previousDay(svm.filter.date);
			changeDateFilter(date);
		};

		svm.dateToday = function() {
			changeDateFilter(new Date());
		};

		svm.dateNext = function() {
			var date = dateService.nextDay(svm.filter.date);
			changeDateFilter(date);
		};

		var changeDateFilter = function(date) {

			if (date.getDate() === svm.filter.date.getDate() && date.getMonth() === svm.filter.date.getMonth() && date.getFullYear() === svm.filter.date.getFullYear()) {
				return;
			}

			svm.filter.date = date;

			svm.scroll.reset();

			svm.dateChange();
		};

		svm.gotoJob = function(e, job) {
			
			e.stopPropagation();

			console.log('Loading Job', job.id);

			if (!job.deleted) {
				$location.path('/job/' + job.id);
			}
		};




		svm.filterAssigned = function(item) {
			
			if (!svm.app.keyword) {
				return true;
			}

			var keyword = svm.app.keyword.toLowerCase();

			if (item.assigned_to && item.assigned_to.full_name.toLowerCase().indexOf(keyword) > -1) {
				return true;
			}
			
			if (!item.assigned_to && 'unassigned'.indexOf(keyword) > -1) {
				return true;
			}

			if (item.customer.full_name.toLowerCase().indexOf(keyword) > -1) {
				return true;
			}

			if (item.address.postcode.toLowerCase().indexOf(keyword) > -1) {
				return true;
			}

			if (item.mobile && item.mobile.indexOf(keyword) > -1) {
				return true;
			}

			if (item.phone && item.phone.indexOf(keyword) > -1) {
				return true;
			}

			if (item.address.street.toLowerCase().indexOf(keyword) > -1) {
				return true;
			}

			return false;
		};



		// --------------------------------------------
		// Parameter Change
		// --------------------------------------------

		svm.loadMoreResults = function() {
			svm.filter.skip++;
			//svm.runSearch();
		};

		svm.runSearch = function() {

			console.info('====== START SEARCH');

			svm.scroll.disabled = true;

			var params = filterService.getSearchParams();

			if (!filterService.hasMinimumParams(params)) {

				/*
				notificationService.createNotification({
					http: null,
					code: 's:noparams'
				});
				*/

				console.warn('Insufficient search params', params);

				return;
			}

			if (!filterService.hasFilterChanged()) {

				//hide the settings panel on search
				//svm.settings = false;

				//return;
			}


			console.log('Filter changed', svm.filter);

			console.log('Search parameters', params);

			if (mode.search) {
				if ($mdSidenav('filter').isOpen()) {
					svm.toggleMenu();
				}
			}
			
			Search.jobs(params).then(function(response) {

				svm.loading = true;

				var output = response.data.data.results;

				console.log('Search response', output);

				//Merge results if infinite scrolling.
				if (svm.filter.skip) {
					console.log('union search response');
					svm.results = _.union(svm.results, output, svm.filter.take);
				} else {
					console.log('set search response');
					svm.results = output;
				}

				svm.count = Search.countJobStatuses(svm.results, svm.refine.deleted);

				svm.scroll.disabled = (output.length < svm.filter.take);

			}, notificationService.requestFailed).finally(function() {
				if (mode.search) {
					filterService.setRecentSearchFilter();
				}

				svm.loading = false;
			});
		};

		if (mode.diary) {
			svm.runSearch();
		}

		if (mode.search && filterService.hasRecentSearchFilter()) {
			svm.filter = filterService.getRecentSearchFilter();

			svm.runSearch();
		}
	}

})();