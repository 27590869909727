(function() {
	"use strict";

    angular
        .module('diary')
        .factory('History', historyService);

    historyService.$inject = ['API'];

    function historyService(API) {

		var getJobHistory = function(id) {

			return API.get({
				url: '/history/job/' + id,
				cache : true
			});
		};

		return {
			'getJobHistory' : getJobHistory
		};
	}

})();