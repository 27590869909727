(function() {
	"use strict";

	angular
		.module('diary')
		.factory('APIInterceptor', APIInterceptor);

	APIInterceptor.$inject = ['Storage', 'constants', '$q', '$location', '$rootScope'];

	function APIInterceptor(Storage, constants, $q, $location, $rootScope) {

		var itemsLoading = 0;
		
		var request = function(config) {
			
			//Only add tokens for API calls.
			if (config.url.indexOf(constants.api.resource) > -1) {

				console.log('API request', config);
				
				var token = Storage.get('token');

				if (token) {
					config.headers.Authorization = 'Bearer ' + token;
				}
			}

			itemsLoading++;
            $rootScope.$broadcast("loading:on");

			return config;
		};

		var response = function(response) {

			if (response.config.url.indexOf(constants.api.resource) > -1) {
				console.log('API response', response.config, response.data);
			}

			if ((--itemsLoading) === 0) {

            	$rootScope.$broadcast("loading:off");
            }
			
			return response || $q.when(response);
		};

		var responseError = function(response) {

			if (response) {

				if (response.config.url.indexOf(constants.api.resource) > -1) {
					console.log('API response error', response.config.url);

					if (response.status === 401) {
						$location.path('/login');
					}
				}
			}

			if (!(--itemsLoading)) {                
                $rootScope.$broadcast("loading:off");
            }

			return $q.reject(response);
		};

		return {
			'request' : request,
			'response' : response,
			'responseError' : responseError
		};
	}
})();