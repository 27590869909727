(function() {
	"use strict";

    angular
        .module('diary')
        .factory('jobService', jobService);

    jobService.$inject = ['API', 'dateService', '$filter', 'constants'];

    function jobService(API, dateService, $filter, constants) {

		var jobSchema = {
			'boolean' : ['deleted'],
			'dateTime' : ['date', 'time_of_booking'],
			'object' : ['customer', 'address', 'status', 'assigned_to', 'appointment', 'contact', 'referrer'],
			'string' : ['review', 'description'],
			'number': ['id', 'phone', 'mobile']
		};

		var getJobModel = function() {
		
			return _.cloneDeep({
				id : null,
				deleted: false,
				date: dateService.getToday(),
				appointment: null,
				description: null,
				status: null,
				assigned_to: null,
				customer: null,
				referrer: null,
				time_of_booking : dateService.getToday(),
				review: null,
				contact : {
					name : null,
					number : null
				},
				address: {
					house_number: null,
					street: null,
					city: null,
					postcode: null
				}
			});
		};

		var prepareJob = function(job) {

			_.each(jobSchema.dateTime, function(prop, i) {
				//set to ISO_8601
				job[prop] = $filter('date')(job[prop], constants.timestamp);
			});

			return job;
		};

		var parseJob = function(job) {

			_.each(jobSchema.dateTime, function(prop, i) {
				job[prop] = moment(job[prop]).toDate();
				//job[prop] = new Date(job[prop]);
			});

			return job;
		};

		var hasMinimumProperties = function(job) {
			return !_.isEmpty(_.omit(job, ['id', 'deleted', 'status', 'assigned_to', 'review']));
		};

		var patchJob = function(id, body) {
			return API.patch({
				data: {
					'job' : body
				},
				url: '/job/' + id
			});
		};

		var createJob = function(body) {
			return API.post({
				method: 'POST',
				data: {
					'job': body
				},
				url: '/job'
			});
		};

		var editJob = function(body) {
			return API.put({
				data: {
					'job' : body
				},
				url: '/job/' + body.id
			});
		};

		var getJobById = function(id) {
			return API.get({
				url: '/job/' + id
			});
		};

		return {
			'getJobModel' : getJobModel,
			'prepareJob' : prepareJob,
			'parseJob' : parseJob,
			'hasMinimumProperties' : hasMinimumProperties,
			'patchJob' : patchJob,
			'createJob' : createJob,
			'editJob' : editJob,
			'getJobById' : getJobById
		};
	}

})();