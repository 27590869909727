(function() {
	"use strict";

	angular
		.module('diary')
		.filter('postcode', postcodeFilter);

	postcodeFilter.$inject = ['constants'];

	function postcodeFilter(constants) {
	    return function(input) {

	    	if (!input) {
	    		return input;
	    	}

	    	var postcode;
	    	
	    	switch(constants.locale) {

	    		case "en-gb":

					if (input.length === 5) {
				        postcode = input.substr(0,2) + ' ' + input.substr(2,3);
				    }
				    else if (input.length === 6) {
				        postcode = input.substr(0,3) + ' ' + input.substr(3,3);
				    }
				    else if (input.length === 7) {
				        postcode = input.substr(0,4) + ' ' + input.substr(4,3);
				    }
				  	break;
				
				case "en-ca":
					postcode = input;
					break;

				default:
					postcode = input;
					break;
			}

			return postcode.toUpperCase();
		};
	}

})();