(function() {
	"use strict";

	angular
		.module('diary')
		.filter('titleCase', titleCaseFilter);

	titleCaseFilter.$inject = [];

	function titleCaseFilter() {
		return function(input) {
			return _.titleCase(input);
		};
	}

})();