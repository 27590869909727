(function() {
	"use strict";

	angular
		.module('diary')
		.directive('scrollable', scrollableDirective);

	scrollableDirective.$inject = ['$window'];

	function scrollableDirective($window) {
		return {
			restrict : 'C',
			link : function(scope, element, attr) {

				var scrollNode = element[0];

				var scroll = function(e) {

					var columns = scrollNode.querySelectorAll('th');
					
					columns.forEach(function(column) {
						column.style.transform = 'translate(0,' + scrollNode.scrollTop + 'px)';
					});
				};

				element.on('scroll', scroll);

				scope.$on('$destroy', function() {
					element.off('scroll', scroll);
				});
			}
		};
	}

})();