(function() {
	"use strict";

	angular
		.module('diary')
		.controller('ChangePasswordController', ChangePasswordController);

	ChangePasswordController.$inject = ['User', 'Auth', 'Feedback', 'notificationService', '$mdDialog'];

	function ChangePasswordController(User, Auth, Feedback, notificationService, $mdDialog) {

		var mvm = this;

		mvm.user = _.cloneDeep(mvm.data.user);

		mvm.validation = Feedback;

		mvm.isAdminOverride = function() {
			return Auth.isAdmin() && mvm.data.override;
		};

		mvm.submit = function() {

			delete mvm.message;

			var promise = (mvm.isAdminOverride()) ? User.overridePassword(mvm.user) : User.changePassword(mvm.user);

			promise.then(function(response) {
				
				$mdDialog.hide();
					
				notificationService.createNotification({
					http: response,
					code: 'a:changePassword'
				});

			}, function(response) {
				mvm.message = {
					text : response.data.data.message,
					errors : response.data.data.errors
				};
			});
		};


		mvm.cancel = function() {
			$mdDialog.cancel();
		};
	}
})();