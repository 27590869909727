(function() {
	"use strict";

    angular
        .module('diary')
        .factory('Storage', storageService);

    storageService.$inject = ['$window'];

    function storageService($window) {

    	var set = function(key, val) {
			$window.localStorage[key] = val;
		};
		
		var get = function(key) {
			return $window.localStorage[key];
		};

		var destroy = function(key) {
			delete $window.localStorage[key];
		};

		return {
			'get' : get,
			'set' : set,
			'destroy' : destroy
		};
	}

})();